import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';



const IndexPage = () => (
  <>
  <Helmet
    title="Horaire d'ouverture Bar Restaurant Le relais de l'avance"
    meta={[
      { name: 'description', content: "Horaire d'ouvreture du relais de l'avance - Bar Restaurant Epicerie Traiteur situé à Saint-Étienne-le-Laus dans les Hautes Alpes 05" },
      { name: 'og:title', content: "Relais de l'avance Restaurant traiteur mariage Hautes Alpes 05"},
      { name: 'og:description', content: "Le relais de l'avance est un Bar - restaurant - traiteur pour mariage avec le concept resto'roul situé à  Saint-Étienne-le-Laus dans les Hautes Alpes 05"},
      { name: 'og:type', content: "website"}
    ]}
    >
  </Helmet>
  <Layout activeLink="horaire">
    <section className="page-section cta shadow1">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <div className="cta-inner text-center rounded shadow1">
              <h1 className="section-heading mb-5 section-heading-lower">
                Horaire d'ouverture du Restaurant et Epicerie Le Relais de L'avance
              </h1>
              <ul className="list-unstyled list-hours mb-5 text-left mx-auto">
                <li className="list-unstyled-item list-hours-item d-flex">
                  Lundi
                  <span className="text-right ml-auto">De 6h51 jusqu'à <br /> la fin du service de midi</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Mardi
                  <span className="text-right ml-auto">fermé</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Mercredi
                  <span className="text-right ml-auto">6h51 à 20h43</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Jeudi
                  <span className="text-right ml-auto">6h51 à 20h43</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Vendredi
                  <span className="text-right ml-auto">6h51 à 20h43</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Samedi
                  <span className="text-right ml-auto">6h51 à 20h43</span>
                </li>
                <li className="list-unstyled-item list-hours-item d-flex">
                  Dimanche
                  <span className="text-right ml-auto">De 6h51 jusqu'à <br/> la fin du service de midi</span>
                </li>
              </ul>
              <p className="address mb-5">
                <em>
                  <strong>La Gravière</strong>
                  <br />
                  05130 Saint-Étienne-le-Laus
                </em>
              </p>
              <p className="mb-0">
                <small>
                  <em>Nous appeler</em>
                </small>
                <br />
                04 92 21 82 01
                <br />
                06 31 00 44 92
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
  </>
);

export default IndexPage;
